@font-face {
  font-family: SkyTextBold;
  src: url("../assets/fonts/SkyText_W_Bd.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/SkyText_W_Bd.woff") format("woff"),
    url("../assets/fonts/SkyText_W_Bd.ttf") format("truetype");
}
@font-face {
  font-family: SkyTextMedium;
  src: url("../assets/fonts/SkyText_W_Md.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/SkyText_W_Md.woff") format("woff"),
    url("../assets/fonts/SkyText_W_Md.ttf") format("truetype");
}
@font-face {
  font-family: SkyTextLight;
  src: url("../assets/fonts/SkyText_W_Lt.ttf") format("woff"),
    url("../assets/fonts/SkyText_W_Lt.ttf") format("truetype"),
    url("../assets/fonts/SkyText_W_Lt.eot?#iefix")
      format("embedded-opentype");
}
@font-face {
  font-family: SkyTextRegular;
  src: url("../assets/fonts/SkyText_W_Rg.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/SkyText_W_Rg.woff") format("woff"),
    url("../assets/fonts/SkyText_W_Rg.ttf") format("truetype");
}
