html, body, #root {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.App {
  height: 100%;
  margin: 0;
  display: flex;
  flex-flow: column;
}

#signedInView {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
}

#uploader {
  flex-shrink: 1;
}

#three {
  flex-grow: 1;
}

mgt-file-list {
  width: 100%;
}